export const SUPER_ADMIN = 'super_admin'
export const STO_OWNER = 'sto_owner'
export const STO_ADMIN = 'sto_admin'
export const WASH_OWNER = 'wash_owner'
export const WASH_ADMIN = 'wash_admin'

export const SUPER_ADMIN_TRANSLATE = 'Администратор системы'
export const STO_OWNER_TRANSLATE = 'Владелец СТО'
export const STO_ADMIN_TRANSLATE = 'Администратор СТО'
export const WASH_OWNER_TRANSLATE = 'Владелец автомойки'
export const WASH_ADMIN_TRANSLATE = 'Администратор автомойки'

export const ROLES_TRANSLATIONS = {
  [SUPER_ADMIN]: SUPER_ADMIN_TRANSLATE,
  [STO_OWNER]: STO_OWNER_TRANSLATE,
  [STO_ADMIN]: STO_ADMIN_TRANSLATE,
  [WASH_OWNER]: WASH_OWNER_TRANSLATE,
  [WASH_ADMIN]: WASH_ADMIN_TRANSLATE,
}

export const ROLES_TRANSLATIONS_TO_ROLE = {
  [SUPER_ADMIN_TRANSLATE]: SUPER_ADMIN,
  [STO_OWNER_TRANSLATE]: STO_OWNER,
  [STO_ADMIN_TRANSLATE]: STO_ADMIN,
  [WASH_OWNER_TRANSLATE]: WASH_OWNER,
  [WASH_ADMIN_TRANSLATE]: WASH_ADMIN,
}

export const ROLES_HIERARCHY = {
  [SUPER_ADMIN]: 0,
  [STO_OWNER]: 1,
  [WASH_OWNER]: 1,
  [WASH_ADMIN]: 2,
  [STO_ADMIN]: 2,
}
