<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="2.5" width="24" height="4" rx="1" fill="#1A436E" />
    <rect y="10.5" width="24" height="4" rx="1" fill="#1A436E" />
    <rect y="18.5" width="24" height="4" rx="1" fill="#1A436E" />
  </svg>
</template>
