import { validatePrice } from '@/lib/utils/validate'

export const validateCardYear = value => {
  const currentYear = new Date().getFullYear()

  let isValidEqual = true
  let message = 'Некорректные данные'

  if (Number(`20${value}`) < Number(currentYear)) {
    isValidEqual = false
    message = 'Год не может быть меньше текущего'
  }

  return {
    isValid:
      validatePrice(value) &&
      (value.length === 4 || value.length === 2) &&
      isValidEqual,
    message,
  }
}

export const validateCardMonth = (value, yearValue) => {
  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()

  let isValidEqual = true
  let message = 'Некорректные данные'

  if (Number(`20${yearValue}`) === currentYear) {
    if (Number(value) < currentMonth) {
      isValidEqual = false
      message = 'Месяц не может быть меньше текущего'
    }
  }

  return {
    isValid:
      validatePrice(value) &&
      value.length === 2 &&
      Number(value) >= 1 &&
      Number(value) <= 12 &&
      isValidEqual,
    message,
  }
}

export const validateCardPan = (value, key) => {
  if (key !== 'pan') {
    return {
      isValid: true,
    }
  }

  const lastValues = value.slice(-4)
  return {
    isValid: value.length === 19 && /^[0-9]+$/g.test(lastValues),
  }
}
