<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19" r="19" fill="#ECFAFF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4975 14.5526C19.4728 11.307 22.28 8.12744 26.1991 5.38487C26.5168 5.16254 26.8832 5.56137 26.6483 5.86989C24.605 8.55349 22.7747 11.5572 21.5493 14.617C26.2134 14.8616 29.6396 15.6762 29.6396 16.6434C29.6396 17.6947 25.5914 18.5658 20.3055 18.7205C19.3795 23.3767 20.262 27.8896 24.337 31.326C24.6425 31.5835 24.3276 32.2886 23.9364 32.2075C20.6049 31.5168 16.1668 31.064 14.0427 32.409C13.8087 32.5572 13.4652 32.4431 13.4286 32.1686C13.0036 28.9779 13.2637 23.9362 15.426 18.6553C10.8971 18.3943 7.59961 17.5923 7.59961 16.6434C7.59961 15.555 11.9384 14.6599 17.4975 14.5526Z"
      fill="url(#paint0_linear_737_2589)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_737_2589"
        x1="21.7737"
        y1="15.6477"
        x2="21.746"
        y2="33.3052"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6C11C7" />
        <stop offset="0.229167" stop-color="#9549E2" />
        <stop offset="0.901042" stop-color="#49F3FE" />
      </linearGradient>
    </defs>
  </svg>
</template>
