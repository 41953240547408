<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#4B98F7" />
    <path
      d="M12.5 16C13.3284 16 14 15.3284 14 14.5C14 13.6716 13.3284 13 12.5 13C11.6716 13 11 13.6716 11 14.5C11 15.3284 11.6716 16 12.5 16Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.5 21.5C28.3284 21.5 29 20.8284 29 20C29 19.1716 28.3284 18.5 27.5 18.5C26.6716 18.5 26 19.1716 26 20C26 20.8284 26.6716 21.5 27.5 21.5Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 27C13.3284 27 14 26.3284 14 25.5C14 24.6716 13.3284 24 12.5 24C11.6716 24 11 24.6716 11 25.5C11 26.3284 11.6716 27 12.5 27Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.5 14.5H24H12"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.5 20H24H12"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.5 25.5H24H12"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
