import { api } from '@/api'

export const getCompanyTypes = async () => {
  const {
    data: { data },
    status,
  } = await api.get(`api/company-types`)

  return { data, status }
}
