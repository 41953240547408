import { defineStore } from 'pinia'
export const useUserData = defineStore('userData', {
  state: () => ({
    userData: null,
    newUserPassword: '',
  }),

  actions: {
    setNewUserPassword(password) {
      localStorage.setItem('newUserPassword', password)
    },

    getNewUserPassword() {
      this.newUserPassword = localStorage.getItem('newUserPassword')
      return this.newUserPassword
    },

    removeNewUserPassword() {
      localStorage.removeItem('newUserPassword')
    },

    setUserData(data) {
      localStorage.setItem('userData', data)
    },

    getUserData() {
      this.userData = JSON.parse(localStorage.getItem('userData')).data.data
      return this.userData
    },

    removeUserData() {
      localStorage.removeItem('userData')
    },
  },
})
