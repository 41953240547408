
import { useUserStore } from './store/userStore'

import AppHeader from '@/components/AppHeader/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar/AppSidebar.vue'
import NotifyInstall from '@/components/NotifyInstall/App.vue'

export default {
  components: {
    AppHeader,
    AppSidebar,
    NotifyInstall,
  },
  setup() {
    const userStore = useUserStore()

    return { userStore }
  },
}
