export function dateFormatting(date: string): string {
  const inputDate = new Date(date)

  const year = inputDate.getUTCFullYear()
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0')
  const day = String(inputDate.getUTCDate()).padStart(2, '0')
  const hours = String(inputDate.getUTCHours()).padStart(2, '0')
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0')
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0')

  const fullDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return fullDate
}

export const generateDays = (length: number = 31) => {
  console.log(Array.from({ length: length }, (_, index) => index))
  return Array.from({ length: length }, (_, index) => index)
}

export const generateHours = (length: number = 24) =>
  Array.from({ length: length }, (_, index) => index)

export const generateMinutes = (length: number = 60) =>
  Array.from({ length: length }, (_, index) => index)

export function convertTimeToMinutes(days, hours, minutes) {
  return days * 1440 + hours * 60 + minutes
}

export function convertMinutesToTime(totalMinutes) {
  const days = Math.floor(totalMinutes / 1440)
  totalMinutes %= 1440
  const hours = Math.floor(totalMinutes / 60)
  totalMinutes %= 60
  const minutes = totalMinutes

  return {
    days,
    hours,
    minutes,
  }
}
