import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import Cookies from 'js-cookie'

declare global {
  interface Window {
    Pusher: typeof Pusher
  }
}

const {
  VUE_APP_PUSHER_APP_KEY,
  VUE_APP_PUSHER_HOST,
  VUE_APP_PUSHER_PORT,
  VUE_APP_PUSHER_APP_CLUSTER,
} = process.env

if (VUE_APP_PUSHER_APP_KEY) {
  window.Pusher = Pusher
}
let echo = null
const initEcho = () => {
  const token = Cookies.get('user_token')
  if (echo) {
    return
  }
  if (token) {
    echo = new Echo({
      broadcaster: 'pusher',
      key: VUE_APP_PUSHER_APP_KEY,
      wssHost: VUE_APP_PUSHER_HOST,
      wssPort: parseInt(VUE_APP_PUSHER_PORT),
      wsHost: VUE_APP_PUSHER_HOST,
      wsPort: parseInt(VUE_APP_PUSHER_PORT),
      cluster: VUE_APP_PUSHER_APP_CLUSTER,
      forceTLS: false,
      disableStats: true,
      authEndpoint: `http://${VUE_APP_PUSHER_HOST}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      },
    })
  }
}

export { initEcho, echo }
