<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.5 18.5L14.5 12L9.5 5.5"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>
