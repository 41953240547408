import { api } from '@/api'

export const getData = async url => {
  const {
    data: { data },
    status,
  } = await api.get(`api/${url}`)

  return { data, status }
}

export const createData = async (url, requestData) => {
  const {
    data: { data },
    status,
  } = await api.post(`api/${url}`, requestData)

  return { data, status }
}

export const updateData = async (url, id, requestData) => {
  const {
    data: { data },
    status,
  } = await api.put(`api/${url}/${id}`, requestData)

  return { data, status }
}

export const deleteData = async (url, id) => {
  const {
    data: { data },
    status,
  } = await api.delete(`api/${url}/${id}`)

  return { data, status }
}
