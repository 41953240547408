import { defineStore } from 'pinia'

export const useIsEditingStore = defineStore('isEditing', {
  state: () => ({
    isEditing: JSON.parse(sessionStorage.getItem('isEditing')) || false,
  }),

  actions: {
    setIsEditing(isEditing) {
      this.isEditing = isEditing
      sessionStorage.setItem('isEditing', JSON.stringify(isEditing))
    },

    toggleIsEditing() {
      this.isEditing = !this.isEditing
      sessionStorage.setItem('isEditing', JSON.stringify(this.isEditing))
    },

    resetIsEditing() {
      this.isEditing = false
      sessionStorage.setItem('isEditing', JSON.stringify(false))
    },
  },
})
