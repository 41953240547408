import { defineStore } from 'pinia'
import Cookies from 'js-cookie'

interface User {
  email: string | null
  name: string | null
  role: string | null
  locale: string | null
}

interface UserState extends User {
  token: string | null
  isAuthorized: boolean
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    email: null,
    name: null,
    role: null,
    locale: null,
    token: null,
    isAuthorized: false,
  }),
  actions: {
    login(user: User, token: string) {
      this.email = user.email
      this.name = user.name
      this.role = user.role
      this.locale = user.locale
      this.token = token
      this.isAuthorized = true
      this.saveToCookies()
    },

    logout() {
      this.email = null
      this.name = null
      this.role = null
      this.locale = null
      this.token = null
      this.isAuthorized = false
      this.removeFromCookies()
    },

    saveToCookies() {
      const userObject = {
        email: this.email,
        name: this.name,
        role: this.role,
        locale: this.locale,
      }

      Cookies.set('user_data', JSON.stringify(userObject))
      Cookies.set('user_token', this.token || '')
      Cookies.set('is_authorized', this.isAuthorized || '')
    },

    removeFromCookies() {
      Cookies.remove('user_data')
      Cookies.remove('user_token')
      Cookies.remove('is_authorized')
    },

    loadFromCookies() {
      const userObject = Cookies.get('user_data')
      if (userObject) {
        const parsedUser = JSON.parse(userObject)
        this.email = parsedUser.email || null
        this.name = parsedUser.name || null
        this.role = parsedUser.role || null
        this.locale = parsedUser.locale || null
      }
      this.token = Cookies.get('user_token') || null
      this.isAuthorized = Cookies.get('is_authorized') || null
    },

    getLoginData() {
      return {
        email: this.email,
        name: this.name,
      }
    },

    isAuthed() {
      return this.isAuthorized
    },
  },
})
