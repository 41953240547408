const EMAIL_PATTERN = 
  /^([а-яА-ЯёЁa-zA-Z0-9_.+-])+@([а-яА-ЯёЁa-zA-Z0-9-])+\.[а-яА-ЯёЁa-zA-Z0-9-.]+$/;
const PASSWORD_PATTERN = /^[^\u0400-\u04FF]+$/u;
const LATIN_PATTERN = /^[a-zA-Z\s]+$/u;
const CYRILLIC_PATTERN = /^[а-яА-ЯёЁ\s]+$/u;
const NONE_CYRILLIC_PATTERN = /^[^\u0400-\u04FF]+$/u;
const ALL_LETTERS_PATTERN = /^[a-zA-Zа-яА-Я\s]+$/u;
const PRICE_PATTERN = /^[\d.]+$/u;
const NUMBER_PATTERN = /\d+/g;

export const validateAllLetters = (value: string) => {
  return ALL_LETTERS_PATTERN.test(value)
}

export const validateLatinLetters = (value: string) => {
  return LATIN_PATTERN.test(value)
}

export const validateNonLatinLetters = (value: string) => {
  return LATIN_PATTERN.test(value)
}

export const validateCyrillicLetters = (value: string) => {
  return CYRILLIC_PATTERN.test(value)
}

export const validateNonCyrillicLetters = (value: string) => {
  return NONE_CYRILLIC_PATTERN.test(value)
}

export const validatePrice = (value: string) => {
  return PRICE_PATTERN.test(value)
}

export const validateNumbers = (value: string) => {
  return NUMBER_PATTERN.test(value)
}

export const validateEmail = (value: string) => {
  return EMAIL_PATTERN.test(value)
}

export const validatePassword = (value: string) => {
  return PASSWORD_PATTERN.test(value) && value.length >= 6
}

export const isEmptyValue = (value: string | unknown[]) => {
  return value === null || value.length === 0
}
