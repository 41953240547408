<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18 9.49998H18.5V8.99998V8.00001L21.1667 9.99999L18.5 12V11V10.5H18H11.4997L11.4998 9.80119L11.4999 9.49998H18ZM3.99969 3.50002H3.49969V4.00002V20V20.5H3.99969H13.9996H14.4996V20V14.5H15.4996V20C15.4996 20.3978 15.3416 20.7794 15.0602 21.0607C14.7789 21.342 14.3974 21.5 13.9996 21.5H3.99969C3.60188 21.5 3.22038 21.342 2.93906 21.0607C2.65776 20.7794 2.49972 20.3978 2.49972 20V4.00002C2.49972 3.6022 2.65776 3.22066 2.93906 2.93936C3.22038 2.65805 3.60188 2.50002 3.99969 2.50002H13.9996C14.3974 2.50002 14.7789 2.65806 15.0602 2.93936C15.3416 3.22066 15.4996 3.6022 15.4996 4.00002V5.50002H14.4996V4.00002V3.50002H13.9996H3.99969Z"
      fill="#1A436E"
      stroke="#1A436E"
    />
  </svg>
</template>
