<template>
  <template>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3729_6144)">
        <path
          d="M9.99994 12.5001L6.46411 8.96422L7.64328 7.78589L9.99994 10.1434L12.3566 7.78589L13.5358 8.96422L9.99994 12.5001Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3729_6144">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </template>
</template>
