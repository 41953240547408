import { api } from '@/api'

export const getWashTypes = async () => {
  const {
    data: { data },
    status,
  } = await api.get(`api/wash-types`)

  return { data, status }
}
