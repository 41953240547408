import {
  validateEmail,
  validateLatinLetters,
  validatePassword,
  validatePrice,
} from '@/lib/utils/validate'
import {
  validateCardMonth,
  validateCardYear,
  validateCardPan,
} from '@/modules/ViewsEntityControl/utils'

export const getValidateRules = (key, value, values) => {
  const VALIDATE_RULES = {
    name: { isValid: value.length >= 1 },
    password: { isValid: validatePassword(value) },
    email: { isValid: validateEmail(value) },
    time: { isValid: validatePrice(value) },
    price: { isValid: validatePrice(value) },
    holder: { isValid: validateLatinLetters(value) },
    pan: validateCardPan(value, key),
    mm: validateCardMonth(value, values.yy),
    yy: validateCardYear(value),
    cvv: { isValid: validatePrice(value) && value.length == 3 },
  }

  return VALIDATE_RULES[key]
}

export const getFieldMask = (key, value) => {
  const VALIDATE_RULES = {
    holder: {
      mask: 'ZZZZZZZZZZZZZZZZZZZZZZZZZZZ',
      pattern: 'Z:[a-zA-Z ]',
    },
    pan: {
      mask: 'ZZZZ ZZZZ ZZZZ ZZZZ',
      pattern: 'Z:[0-9*]',
    },
    mm: {
      mask: '##',
    },
    yy: {
      mask: '##',
    },
    cvv: {
      mask: '###',
    },
  }

  return VALIDATE_RULES[key] || ''
}
