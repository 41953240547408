<template>
  <div
    style="
      width: 24px;
      height: 24px;
      position: relative;
      left: -30%;
      top: 5%;
      cursor: pointer;
    "
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linejoin: round;
        stroke-miterlimit: 2;
      "
    >
      <rect
        id="Icons"
        x="-896"
        y="-256"
        width="1280"
        height="800"
        style="fill: none"
      />
      <g id="Icons1" serif:id="Icons">
        <g id="Strike"></g>
        <g id="H1"></g>
        <g id="H2"></g>
        <g id="H3"></g>
        <g id="list-ul"></g>
        <g id="hamburger-1"></g>
        <g id="hamburger-2"></g>
        <g id="list-ol"></g>
        <g id="list-task"></g>
        <g id="trash"></g>
        <g id="vertical-menu"></g>
        <g id="horizontal-menu"></g>
        <g id="sidebar-2"></g>
        <g id="Pen"></g>
        <g id="Pen1" serif:id="Pen"></g>
        <g id="clock"></g>
        <g id="external-link"></g>
        <g id="hr"></g>
        <g id="info"></g>
        <g id="warning"></g>
        <g id="plus-circle"></g>
        <g id="minus-circle"></g>
        <g id="vue"></g>
        <g id="cog"></g>
        <g id="logo"></g>
        <g id="eye-slash"></g>
        <g id="eye">
          <path
            d="M32.513,13.926c10.574,0.15 19.249,9.657 23.594,17.837c0,0 -1.529,3.129 -2.963,5.132c-0.694,0.969 -1.424,1.913 -2.191,2.826c-0.547,0.65 -1.112,1.283 -1.698,1.898c-5.237,5.5 -12.758,9.603 -20.7,8.01c-8.823,-1.77 -16.02,-9.33 -20.346,-17.461c0,0 1.536,-3.132 2.978,-5.132c0.646,-0.897 1.324,-1.77 2.034,-2.617c0.544,-0.649 1.108,-1.282 1.691,-1.897c4.627,-4.876 10.564,-8.63 17.601,-8.596Zm-0.037,4c-5.89,-0.022 -10.788,3.267 -14.663,7.35c-0.527,0.555 -1.035,1.127 -1.527,1.713c-0.647,0.772 -1.265,1.569 -1.854,2.386c-0.589,0.816 -1.193,1.846 -1.672,2.721c3.814,6.409 9.539,12.198 16.582,13.611c6.563,1.317 12.688,-2.301 17.016,-6.846c0.529,-0.555 1.04,-1.128 1.534,-1.715c0.7,-0.833 1.366,-1.694 1.999,-2.579c0.586,-0.819 1.189,-1.851 1.667,-2.727c-3.958,-6.625 -10.73,-13.784 -19.082,-13.914Z"
          />
          <path
            d="M32.158,23.948c4.425,0 8.018,3.593 8.018,8.017c0,4.425 -3.593,8.017 -8.018,8.017c-4.424,0 -8.017,-3.592 -8.017,-8.017c0,-4.424 3.593,-8.017 8.017,-8.017Zm0,4.009c2.213,0 4.009,1.796 4.009,4.008c0,2.213 -1.796,4.009 -4.009,4.009c-2.212,0 -4.008,-1.796 -4.008,-4.009c0,-2.212 1.796,-4.008 4.008,-4.008Z"
          />
        </g>
        <g id="toggle-off"></g>
        <g id="shredder"></g>
        <g id="spinner--loading--dots-" serif:id="spinner [loading, dots]"></g>
        <g id="react"></g>
      </g>
    </svg>
  </div>
</template>
