import { api } from '@/api'

export const getUncleanTypes = async () => {
  const {
    data: { data },
    status,
  } = await api.get(`api/unclean-types`)

  return { data, status }
}
