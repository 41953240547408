<script setup>
import VSelect from 'vue-select'
import { useSlots, watch, ref, onBeforeUnmount, computed, onMounted } from 'vue'
import IconSearch from '../icons/IconSearch.vue'
import OpenIndicator from '../icons/OpenIndicator.vue'
import VueSelect from 'vue-select'
import { dateFormatting } from '@/lib/utils/date'
import { ElDatePicker } from 'element-plus'
import 'element-plus/dist/index.css'
import { createPopper } from '@popperjs/core'
import { parseISO, format } from 'date-fns'

const emit = defineEmits(['changeValue'])

const props = defineProps({
  size: {
    type: String,
    default: 'grand',
  },
  isForm: Boolean,
  placeholder: {
    type: String,
    default: '',
  },
  disabled: Boolean,
  inputType: {
    type: String,
    default: 'input',
  },
  type: String,
  fullWidth: Boolean,
  isSwitchLang: Boolean,
  options: {
    type: Array,
    default: [],
  },
  value: {
    type: String,
    default: '',
  },
  noIconClose: Boolean,
  noReset: Boolean,
  noLabel: Boolean,
})

const val = ref(props.value || props.options[0])
const hasIcon = useSlots().default

if (props.noLabel) {
  if (typeof props.value === 'number') {
    val.value = props.options.find(option => option.value === props.value)
  }
}

watch(
  () => props.value,
  () => {
    if (props.noLabel) {
      if (typeof props.value === 'number') {
        val.value = props.options.find(option => option.value === props.value)
      }
    } else {
      val.value = props.value
    }
  }
)

watch(
  () => val.value,
  () => {
    emit('changeValue', val.value)
  }
)
const placement = 'bottom'
const withPopper = (dropdownList, component, { width }) => {
  dropdownList.style.width = width

  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -1],
        },
      },
      {
        name: 'toggleClass',
        enabled: true,
        phase: 'write',
        fn({ state }) {
          component?.$el.classList.toggle('drop-up', (state.placement = 'top'))
        },
      },
    ],
  })

  onBeforeUnmount(() => {
    popper.destroy()
  })
}
const selectedDate = ref(parseISO(props.value)) // Преобразуем строку в объект Date

// Обновляем выбранную дату при изменении пропса value
onMounted(() => {
  selectedDate.value = parseISO(props.value)
})
</script>

<template>
  <div
    class="input customVSelect"
    :class="[
      size,
      {
        hasIcon: hasIcon,
        disabled: disabled,
        fullWidth: fullWidth,
        isSwitchLang: isSwitchLang,
        isForm: isForm,
      },
    ]"
  >
    <v-select
      v-if="inputType === 'select'"
      class="select"
      :components="OpenIndicator"
      :class="{ noIconClose: noIconClose }"
      :placeholder="placeholder"
      :options="options"
      v-model="val"
      :searchable="false"
      appendToBody
      :calculate-position="withPopper"
      @search:blur="props.value === val ? $emit('blur') : false"
    ></v-select>

    <textarea
      v-else-if="inputType === 'textarea'"
      @blur="$emit('blur')"
      :placeholder="placeholder"
      v-model="val"
      :disabled="disabled"
    />

    <div v-else-if="inputType === 'input-search'">
      <div @click="$emit('iconClicked')" class="button-search">
        <IconSearch />
      </div>

      <input
        v-model="val"
        :placeholder="placeholder"
        :disabled="disabled"
        class="input-search"
        @blur="$emit('blur')"
      />
    </div>

    <el-date-picker
      v-else-if="inputType === 'date-picker'"
      v-model="selectedDate"
      class="date-picker"
      type="datetime"
      format="dd.MM.yyyy HH:mm:ss"
      :placeholder="placeholder"
    ></el-date-picker>

    <input
      v-else
      :type="type"
      v-model="val"
      :placeholder="placeholder"
      :disabled="disabled"
      @blur="$emit('blur')"
    />

    <div v-if="hasIcon" @click="$emit('iconClicked')" class="button">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input {
  position: relative;
  width: 100%;
  max-width: 175px;

  &.isForm {
    max-width: 300px;

    input {
      border-radius: 10px;
      border: 1px solid #4b98f7;
    }
  }

  &.fullWidth {
    min-width: 100%;
  }

  textarea {
    min-height: 62px;
  }

  input,
  textarea {
    height: 100%;
    resize: vertical;
    padding: 10.5px 8px;
    color: #1f1f1f;
    background: #fff;
    font-family: Inter;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    border-radius: 4px;
    appearance: none;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    width: 100%;
    transition: all 0.2s ease 0s;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: #1f1f1f;
      opacity: 0.8;
    }

    &:hover {
      background: #efefef;
    }

    &:focus {
      outline: none;
      border-color: #4b98f7;
    }
  }

  &.small {
    input {
      padding: 1px 8px;
    }
  }

  .button {
    top: 0;
    right: 10px;
    height: 100%;
    position: absolute;
    z-index: 10;
    appearance: none;
    background: none;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    outline: none;
  }

  .button-search {
    top: 0;
    left: 10px;
    height: 100%;
    position: absolute;
    z-index: 10;
    appearance: none;
    background: none;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 2;
  }

  .input-search {
    padding-left: 42px;
  }

  &.disabled {
    input,
    textarea {
      color: #888 !important;
      background: #f8f8f8;
    }
  }
}
</style>

<style lang="scss">
.select {
  .el-input__wrapper {
    &.is-focus {
      box-shadow: none !important;
    }
  }
}

.date-picker {
  .el-input__wrapper {
    max-width: 195px;
  }
}

.el-input__inner {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  height: fit-content !important;
  line-height: 1 !important;
}

.el-input__wrapper {
  border: 1px solid #e8e8e8 !important;
  padding: 8px;

  &.is-focus {
    box-shadow: var(--field-shadow-focus) !important;
  }
}

.el-date-picker {
  font-family: Inter;
}

:root {
  --el-color-primary: #4b98f7;
}

.el-date-editor.el-input,
.el-date-editor.el-input__wrapper {
  height: var(--size-grand) !important;
}

.el-input__wrapper {
  box-shadow: none !important;
  border: 1px solid var(--color-gray-500);
}

.el-input__inner {
  border: none !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.customVSelect {
  &.isSwitchLang {
    .vs__search:focus {
      padding: 7px;
    }

    .vs__search {
      padding: 7px;
    }
  }

  .vs__selected-options {
    display: block;
    padding: 0;
  }

  .vs__dropdown-toggle,
  .vs__search,
  .vs__search:focus {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  &.small {
    .vs__search,
    .vs__search:focus {
      padding: 1px 8px;
    }
  }

  .vs__search {
    width: 100%;
    padding: 10.5px 8px;
  }

  .vs__dropdown-toggle {
    transition: all 0.2s ease 0s;
    border: 1px solid #e8e8e8;

    &:hover {
      background: #efefef;
    }
  }

  .v-select {
    &.noIconClose {
      .vs__clear {
        display: none;
      }
    }
  }

  .v-select.vs--open {
    .vs__dropdown-toggle {
      border-color: #4b98f7;
    }
  }

  .vs__search:focus {
    padding: 10.5px 8px;
    outline: none;
  }

  .vs__search:focus {
    border: none;
    width: 100%;
  }

  .vs--unsearchable:not(.vs--disabled) .vs__search {
    color: #1f1f1f;
    opacity: 0.8;
    font-size: 16px;
    font-weight: 400;
    font-family: inherit;
  }

  .vs__dropdown-menu {
    padding: 0;
  }

  .vs__actions {
    padding: 0;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }

  // .vs__clear {
  //     display: none;
  // }

  .vs__selected {
    position: absolute;
    height: 100%;
    padding: 0 10px;
    padding-bottom: 0;
    margin: 0;
  }

  .vs__dropdown-menu {
    top: 0;
    width: 100%;
    min-width: 100%;
    border-radius: 0 !important;
    display: grid;
    grid-auto-flow: row;
  }

  .vs__dropdown-option {
    font-size: 16px;
    font-weight: 400;

    &--highlight {
      border-radius: 0;
    }
  }

  .vs__no-options {
    font-size: 16px;
    font-weight: 400;
  }

  &.grand {
    .vs__actions {
      right: 12px;
    }

    .vs__selected {
      font-size: 16px;
      font-weight: 400;
    }

    .vs__dropdown-menu {
      top: var(--size-grand);
    }
  }
}
</style>
