<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.1037 3.5C11.502 2.83333 12.498 2.83333 12.8963 3.5L21.8598 18.5C22.2582 19.1667 21.7602 20 20.9634 20H3.03655C2.2398 20 1.74183 19.1667 2.14021 18.5L11.1037 3.5Z"
      fill="#EC5A29"
    />
    <path
      d="M12.6469 7.81818L12.544 15.1364H11.3502L11.2473 7.81818H12.6469ZM11.9471 18.0795C11.6932 18.0795 11.4754 17.9917 11.2936 17.8161C11.1118 17.6404 11.0209 17.4299 11.0209 17.1847C11.0209 16.9394 11.1118 16.7289 11.2936 16.5533C11.4754 16.3776 11.6932 16.2898 11.9471 16.2898C12.2009 16.2898 12.4188 16.3776 12.6006 16.5533C12.7824 16.7289 12.8733 16.9394 12.8733 17.1847C12.8733 17.3471 12.8304 17.4962 12.7446 17.6321C12.6623 17.768 12.5508 17.8774 12.4102 17.9602C12.273 18.0398 12.1186 18.0795 11.9471 18.0795Z"
      fill="white"
    />
  </svg>
</template>
