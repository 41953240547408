import { defineStore } from 'pinia'
import {
  getCarTypes,
  getCompanies,
  getLocations,
  getRoles,
  getUncleanTypes,
  getWashTypes,
  getData,
  getCompanyTypes,
} from '@/services'
import {
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from '@/lib/utils/storage'

export const useTableData = defineStore('tableData', {
  state: () => ({
    tableData: null,
    cashedList: [],
    ruNamings: {},
    enNamings: {},
    lang: 'ru',
    cashData: {
      allRoles: [],
      locations: [],
      companies: [],
      carTypes: [],
      washTypes: [],
      uncleanTypes: [],
      typeId: [],
    },
  }),

  actions: {
    async updateCashData(url: string) {
      const values = {
        'wash-types': 'washTypes',
        'car-types': 'carTypes',
        'unclean-types': 'uncleanTypes',
        companies: 'companies',
        posts: 'posts',
        locations: 'locations',
        all_roles: 'allRoles',
        type_id: 'typeId',
      }

      const { data } = await getData(url)

      this.cashData[values[url]] = data

      setSessionStorage('cashData', this.cashData)

      return
    },
    async getCustomDetailData() {
      if (!getSessionStorage('cashData')) {
        const responses = await Promise.allSettled([
          getRoles(),
          getLocations(),
          getCompanies(),
          getCarTypes(),
          getWashTypes(),
          getUncleanTypes(),
          getCompanyTypes(),
        ])

        this.cashData = {
          allRoles:
            responses[0].status === 'fulfilled' ? responses[0].value.data : [],
          locations:
            responses[1].status === 'fulfilled' ? responses[1].value.data : [],
          companies:
            responses[2].status === 'fulfilled' ? responses[2].value.data : [],
          carTypes:
            responses[3].status === 'fulfilled' ? responses[3].value.data : [],
          washTypes:
            responses[4].status === 'fulfilled' ? responses[4].value.data : [],
          uncleanTypes:
            responses[5].status === 'fulfilled' ? responses[5].value.data : [],
          typeId:
            responses[6].status === 'fulfilled' ? responses[6].value.data : [],
        }

        setSessionStorage('cashData', this.cashData)

        return this.cashData
      } else {
        return (this.cashData = getSessionStorage('cashData'))
      }
    },

    getLocalTranslation(key) {
      let namings
      if (this.lang === 'ru') {
        namings = this.ruNamings
      } else {
        namings = this.enNamings
      }

      for (let item in namings) {
        if (item === key) {
          return namings[item]
        }
      }
      return key
    },
    setNamings() {
      setLocalStorage('enNamings', {
        all_roles: 'Role',
        name: 'Name',
        cvv: 'Security code',
        company_id: 'Company',
        companies: 'Company',
        employers: 'Employers',
        employer: 'Employer',
        users: 'Users',
        user: 'User',
        car_type_id: 'Car type',
        car_type: 'Car type',
        'car-types': 'Car types',
        'car-type': 'Car type',
        cameras: 'Cameras',
        'company-card': 'Bank card',
        holder: 'Cardowner',
        time_day: 'Days',
        time_hours: 'Hours',
        time_minutes: 'Minutes',
        wash_type_id: 'Wash type',
        wash_type: 'Wash type',
        'payment-types': 'Payment types',
        'payment-type': 'Payment type',
        'wash-types': 'Wash type',
        fio: 'FIO',
        mm: 'Valid month',
        yy: 'Valid year',
        unclean_type_id: 'Unclean type',
        'unclean-type': 'Unclean type',
        'unclean-types': 'Unclean types',
        time: 'Time',
        pan: 'Card number',
        price: 'Price list',
        prices: 'Price list',
        password: 'Password',
        address: 'Address',
        location_id: 'Location',
        posts: 'Posts',
        post: 'Post',
        email: 'Mail',
        site: 'Site',
        kpp: 'KPP',
        phone: 'Phone',
        inn: 'INN',
        type_id: 'Type',
        file: 'Photo',
      })

      setLocalStorage('ruNamings', {
        all_roles: 'Роль',
        name: 'Название',
        cvv: 'Код безопасности',
        company_id: 'Компания',
        company: 'Компания',
        companies: 'Компания',
        employers: 'Сотрудники',
        employer: 'Сотрудник',
        users: 'Пользователи',
        user: 'Пользователь',
        'car-types': 'Тип автомобиля',
        'car-type': 'Тип автомобиля',
        cameras: 'Камеры',
        'company-cards': 'Банковские карты',
        'company-card': 'Банковская карта',
        holder: 'Владелец карты',
        wash_type_id: 'Тип мойки',
        wash_type: 'Тип мойки',
        time_day: 'Дни',
        time_hours: 'Часы',
        time_minutes: 'Минуты',
        'unclean-types': 'Уровень Загрязнения',
        'unclean-type': 'Уровень Загрязнения',
        'payment-types': 'Тип оплаты',
        'payment-type': 'Тип оплаты',
        car_type_id: 'Тип автомобиля',
        car_type: 'Тип автомобиля',
        'wash-types': 'Тип мойки',
        fio: 'ФИО',
        unclean_type_id: 'Уровень загрязнения',
        unclean_type: 'Уровень загрязнения',
        mm: 'Месяц срока действия',
        yy: 'Год срока действия',
        time: 'Время',
        pan: 'Номер карты',
        price: 'Цена',
        prices: 'Прайс-лист',
        password: 'Пароль',
        address: 'Адрес',
        locations: 'Локации',
        location: 'Локация',
        location_id: 'Локация',
        posts: 'Посты',
        post: 'Пост',
        email: 'Почта',
        site: 'Сайт',
        kpp: 'КПП',
        phone: 'Телефон',
        inn: 'ИНН',
        type_id: 'Тип',
        file: 'Фото',
      })
    },
    getNamings() {
      if (
        localStorage.getItem('ruNamings') === 'null' ||
        localStorage.getItem('enNamings') === 'null'
      ) {
        this.setEnNamings()
        this.setRuNamings()
      } else {
        this.ruNamings = JSON.parse(localStorage.getItem('ruNamings'))
        this.enNamings = JSON.parse(localStorage.getItem('enNamings'))
      }
    },
    setCashedList(data) {
      setLocalStorage('cashedList', data)
    },
    getCashedList() {
      if (getLocalStorage('cashedList') == null) {
        return false
      } else {
        return getLocalStorage('cashedList')
      }
    },
  },
})
