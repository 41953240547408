import { api } from '@/api'

export const getCompanies = async () => {
  const {
    data: { data },
    status,
  } = await api.get(`api/companies`)

  return { data, status }
}
