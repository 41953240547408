import { api } from '@/api'

export const passwordRecovery = async email => {
  const { data, status } = await api.post('/forgot-password', { email })

  return { data, status }
}

export const passwordRecoveryConfirm = async body => {
  const { data, status } = await api.post('/reset-password', body)

  return { data, status }
}

export const emailActivate = async ({ id, hash, expires, signature }) => {
  const { data, status } = await api.get(
    `/company/email_activate/${id}/${hash}?expires=${expires}&signature=${signature}`
  )

  return { data, status }
}
