import { api } from '@/api'

export const getData = async (url, id) => {
  const {
    data: { data },
    status,
  } = await api.get(`api/${url}/${id}`)

  return { data, status }
}

export const createData = async (url, requestData) => {
  const {
    data: { data },
    status,
  } = await api.post(`api/${url}`, requestData)

  return { data, status }
}

export const updateData = async (url, id, requestData) => {
  const {
    data: { data },
    status,
  } = await api.post(`api/${url}/${id}`, { ...requestData, _method: 'PUT' })

  return { data, status }
}
