import { api } from '@/api'

export const getPaymentTypes = async () => {
  const {
    data: { data },
    status,
  } = await api.get(`api/payment-types`)

  return { data, status }
}
