<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <circle cx="25" cy="25" r="25" fill="#4B98F7" />
    <path
      d="M34.3337 35.5V33.1667C34.3337 31.929 33.842 30.742 32.9668 29.8668C32.0917 28.9917 30.9047 28.5 29.667 28.5H20.3337C19.096 28.5 17.909 28.9917 17.0338 29.8668C16.1587 30.742 15.667 31.929 15.667 33.1667V35.5"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.9997 23.8333C27.577 23.8333 29.6663 21.744 29.6663 19.1667C29.6663 16.5893 27.577 14.5 24.9997 14.5C22.4223 14.5 20.333 16.5893 20.333 19.1667C20.333 21.744 22.4223 23.8333 24.9997 23.8333Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
