export const formattedDataToDisplay = data => {
  return data.map(item => ({
    value: item.id,
    label: item.name || item.fio,
  }))
}

export const formattedPrice = price => {
  let num = parseFloat(price)

  if (!isNaN(num)) {
    let formattedNum = num
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$& ')
      .replace('.', ',')

    return formattedNum
  }

  return price
}
