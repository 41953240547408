import { api } from '@/api'

export const getPosts = async () => {
  const {
    data: { data },
    status,
  } = await api.get(`api/posts`)

  return { data, status }
}
