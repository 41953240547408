<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_473_3014" fill="white">
      <rect x="1" y="9" width="22" height="7" rx="1" />
    </mask>
    <rect
      x="1"
      y="9"
      width="22"
      height="7"
      rx="1"
      stroke="#1A334D"
      stroke-width="3"
      mask="url(#path-1-inside-1_473_3014)"
    />
    <path
      d="M16 10.5V14.5"
      stroke="#1A334D"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>
