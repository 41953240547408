import { api } from '@/api'

export const getPrices = async () => {
  const {
    data: { data },
    status,
  } = await api.get(`api/prices`)

  return { data, status }
}

export const getPrice = async id => {
  const {
    data: { data },
    status,
  } = await api.get(`api/prices/${id}`)

  console.log('val: ', data)

  return { data, status }
}
